<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.visitDate }}
            </div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="searchConditionsDatePicker"
                    format="YYYY-MM-DD"
                    v-model="visitDateValueModel"
                    type="lookup-condition"
                    :notClear="true"
                    :isGetDateInfo="true"
                    @change="handleSearchConditionsBsnDateChange"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      null,
                      visitDateInfo.bsnCode,
                      visitDateInfo.dwCode
                    ),
                  }"
              >{{ dayOfWeekCaption }}</span
              >) (<span
                  :style="{
                    color: commonCodesGetColorValue(
                      'BSN_CODE',
                      visitDateInfo.bsnCode
                    ),
                  }"
              >{{
                  commonCodesGetComName('BSN_CODE', visitDateInfo.bsnCode)
                }}</span
              >)
              </li>
            </ul>
          </li>
          <li class="field course">
            <div class="title">시간</div>
            <ul class="content">
              <li class="item" style="width: 50px">
                <input-time
                    ref="weekendRqsttm"
                    format="HH:mm"
                    v-model="time"
                />
              </li>
            </ul>
          </li>
          <li class="field course">
            <div class="title">코스</div>
            <ul class="content">
              <li class="item" style="width: 80px">
                <ejs-dropdownlist
                    cssClass="lookup-condition-dropdown"
                    ref="visitSearchCourseCode"
                    v-bind="courseCodeDropdownListProps"
                    v-model="courseCode"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="NONE"
                          v-model="isOver"
                          name="isOver"
                      />
                      <i/>
                      <div class="label">총타수</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="OVER"
                          v-model="isOver"
                          name="isOver"
                      />
                      <i/>
                      <div class="label">오버타수</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onViewVisitScoreRegistration"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition sub">
          <li class="field">
            <ul class="content">
              <li class="item input" style="width: 180px">
                <input-text
                    ref="searchValue"
                    id="searchValueInputTextBox"
                    v-model="searchValue"
                    placeholder="예약자명, 캐디명, 내장객명"
                    @keydown.enter="findMemberNameAndCaddieName"
                    @click="searchValue = ''"
                />
              </li>
            </ul>
            <ul class="content">
              <div class="lookup-lookup">
                <erp-button
                    button-div="GET"
                    :ignore="isPopupOpened"
                    @click.native="findMemberNameAndCaddieName"
                >
                  찾기
                </erp-button>
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">내장객 스코어등록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-label">
                <li class="field">
                  <ul class="content">
                    <li
                        class="item"
                        v-for="(item, index) in scoreDiv" :key="index"
                    >
                      <i :style="{backgroundColor: item.colorValue}"/>
                      <div class="label">{{ item.comName }}</div>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      :is-key-color="true"
                      v-on:click.native="onSaveVisitScoreRegistration"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                      v-model="isModifyMode"
                      :checked="isModifyMode"
                      @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
              class="section-body"
              @keydown.capture="onVisitScoreRegistrationGridSectionBodyKeyDown"
          >
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="visitScoreRegistrationGrid"
                  :isNOColumnDisplay="false"
                  :allowExcelExport="true"
                  :allowFiltering="false"
                  :allowSorting="false"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :provides="grid"
                  :selectionSettings="selectionSettings"
                  :groupSettings="groupSettings"
                  :columns="visitScoreRegistrationGridColumn"
                  :dataSource="visitScoreRegistrationInfoList"
                  @visitEjsDropdownListEditTemplateChanged="onVisitEjsDropdownListEditTemplateChanged"
                  @cellEdit="visitScoreRegistrationGridCellEdit"
                  @cellSaved="visitScoreRegistrationGridCellSaved"
                  @rowSelecting="visitScoreRegistrationGridRecordClick"
                  @headerCellInfo="visitScoreRegistrationGridHeaderCellInfo"
                  @queryCellInfo="visitScoreRegistrationGridQueryCellInfo"
                  @cellSelected="cellSelected"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <edit-multiple-number-popup
        v-if="isEditMultipleNumberPopupOpen"
        ref="editMultipleNumberPopup"
        @popupConfirm="onEditMultipleNumberPopupConfirm"
        @popupClosed="onEditMultipleNumberPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import {DATE_FORMAT_YYYY_MM_DD, getDayOfWeekCaption, getDayOfWeekCaptionColor, getFormattedDate} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {Edit, ExcelExport, ForeignKey, Group, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputText from '@/components/common/text/InputText';
import InputDate from '@/components/common/datetime/InputDate';
import InputTime from '@/components/common/datetime/InputTime';
import editMultipleNumberPopup from '@/views/common/EditMultipleNumberPopup';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import ErpButton from "@/components/button/ErpButton.vue";

import {
  commonCodesGetColorValue,
  commonCodesGetCommonCodeByIdx,
  commonCodesGetComName,
  commonCodeGetComCodeDefaultValue, commonCodesGetCommonCode, commonCodesGetSortNo,
} from '@/utils/commonCodes';
import gridVisitEjsDropdownlistEditTemplate
  from '@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate';
import Vue from 'vue';
import {sortBy as _sortBy} from 'lodash';
import {addShortcut, keypressEventEqualsShortcut, removeShortcut, SHORTCUT_KEYS} from '@/utils/KeyboardUtil';

const SCORE_LABEL = {
  'ACE': 'ACE',
  '-3': 'ACE',
  '-2': 'EAGLE',
  '-1': 'BIRDIE',
  '0': 'PAR',
  '1': 'BOGEY',
  '2': 'DOUBLEBOGEY',
};

const SHORTCUT_FOCUS_SEARCH_VALUE = {
  key: 'VisitScoreRegistration.shortcuts.focusVisitName',
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.Insert,
  },
};

export default {
  name: 'VisitScoreRegistration',
  components: {
    ejsGridWrapper,
    InputText,
    InputDate,
    InputTime,
    editMultipleNumberPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      count: 0,
      labels: {
        visitDate: '영업일자',
      },
      visitDateInfo: {
        bsnCode: null,
        dwCode: null,
      },
      time: '00:00',
      courseCode: '',
      isOver: 'OVER',
      searchValue: '',
      scoreDiv: commonCodesGetCommonCode('SCORE_DIV', true),
      gridVisitEjsDropdownlistEditTemplateEventBus: new Vue(),
      visitScoreRegistrationGridColumn: [
        {
          field: '_rid',
          isPrimaryKey: true,
          visible: false,
          type: 'number',
        },
        {
          field: 'no',
          headerText: 'NO',
          textAlign: 'center',
          allowEditing: false,
          width: 40,
          type: 'number',
        },
        {
          field: 'id',
          visible: false,
          type: 'number',
        },
        {
          field: 'time',
          headerText: '시간',
          textAlign: 'center',
          allowEditing: false,
          width: 50,
          type: 'string',
        },
        {
          field: 'course',
          headerText: '코스',
          textAlign: 'center',
          allowEditing: false,
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 80,
          type: 'string',
        },
        {
          field: 'holeDivision',
          headerText: '홀',
          textAlign: 'center',
          allowEditing: false,
          isCommonCodeField: true,
          groupCode: 'HOLE_DIV',
          width: 50,
          type: 'string',
        },
        {
          field: 'reserveName',
          headerText: '예약자명',
          allowEditing: false,
          width: 80,
          type: 'string',
        },
        {
          field: 'groupName',
          headerText: '단체명',
          allowEditing: false,
          width: 80,
          type: 'string',
        },
        {
          field: 'visitId',
          type: 'string',
          visible: false,
        },
        {
          field: 'visitName',
          headerText: '내장객',
          allowEditing: false,
          width: 90,
          type: 'string',
        },
        {
          field: 'webId',
          headerText: '웹 ID',
          allowEditing: false,
          width: 80,
          type: 'string',
        },
        {
          field: 'startCourse',
          headerText: '출발',
          textAlign: 'center',
          editType: 'dropdownedit',
          allowEditing: true,
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 80,
          type: 'string',
          editTemplate: () =>
              gridVisitEjsDropdownlistEditTemplate(
                  'startCourse',
                  commonCodesGetCommonCode('COURSE_CODE', true),
                  this.$refs.visitScoreRegistrationGrid,
                  this.gridVisitEjsDropdownlistEditTemplateEventBus,
              ),
        },
        {
          field: 'endCourse',
          headerText: '종료',
          textAlign: 'center',
          editType: 'dropdownedit',
          allowEditing: true,
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 80,
          type: 'string',
          editTemplate: () =>
              gridVisitEjsDropdownlistEditTemplate(
                  'endCourse',
                  commonCodesGetCommonCode('COURSE_CODE', true),
                  this.$refs.visitScoreRegistrationGrid,
                  this.gridVisitEjsDropdownlistEditTemplateEventBus,
              ),
        },
        {
          field: 'teeingground',
          headerText: '티그라운드',
          textAlign: 'center',
          editType: 'dropdownedit',
          allowEditing: true,
          isCommonCodeField: true,
          groupCode: 'TEEINGGROUND',
          width: 80,
          type: 'string',
        },
        {
          field: 'holeDivLabel',
          headerText: 'HOLE',
          textAlign: 'center',
          allowEditing: false,
          width: 60,
          type: 'string',
        },
        {
          field: 'score1',
          headerText: '1',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score2',
          headerText: '2',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score3',
          headerText: '3',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score4',
          headerText: '4',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score5',
          headerText: '5',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score6',
          headerText: '6',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score7',
          headerText: '7',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score8',
          headerText: '8',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score9',
          headerText: '9',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score10',
          headerText: '10',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score11',
          headerText: '11',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score12',
          headerText: '12',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score13',
          headerText: '13',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score14',
          headerText: '14',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score15',
          headerText: '15',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score16',
          headerText: '16',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score17',
          headerText: '17',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'score18',
          headerText: '18',
          textAlign: 'center',
          allowEditing: true,
          isHorizontalEdit: true,
          width: 30,
          type: 'number',
        },
        {
          field: 'outScore',
          headerText: 'OUT',
          textAlign: 'center',
          allowEditing: false,
          width: 70,
          type: 'string',
        },
        {
          field: 'outScoreSum',
          type: 'string',
          visible: false,
        },
        {
          field: 'outScorePlus',
          type: 'string',
          visible: false,
        },
        {
          field: 'inScore',
          headerText: 'IN',
          textAlign: 'center',
          allowEditing: false,
          width: 70,
          type: 'string',
        },
        {
          field: 'inScoreSum',
          type: 'string',
          visible: false,
        },
        {
          field: 'inScorePlus',
          type: 'string',
          visible: false,
        },
        {
          field: 'totScore',
          headerText: 'TOT',
          textAlign: 'center',
          allowEditing: false,
          width: 80,
          type: 'string',
        },
        {
          field: 'totScoreSum',
          type: 'string',
          visible: false,
        },
        {
          field: 'totScorePlus',
          type: 'string',
          visible: false,
        },
      ],
      grid: [
        Edit,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
      ],
      selectionSettings: {
        enableToggle: false,
        mode: "Both",
        type: "Multiple",
      },
      courseHoleInfos: {},
      pureVisitScoreRegistrationInfoList: [],
      visitScoreRegistrationInfoList: [],
      selectedVisitScoreRegistrationInfo: {},
      searchConditions: {
        visitDate: new Date(),
      },
      groupSettings: {
        columns: [],
        showDropArea: false,
      },
      isEditMultipleNumberPopupOpen: false,
      isModifyMode: false,
    };
  },
  watch: {
    async isOver() {
      await this.fetch();
    },
  },
  async created() {
    if (this.visitDateValueModel) {
      const {data: {bsnCode, dwCode}} = await GolfErpAPI.fetchCalenderInfo(this.visitDateValueModel, false);
      this.visitDateInfo.bsnCode = bsnCode;
      this.visitDateInfo.dwCode = dwCode;
    }
    addShortcut(
        SHORTCUT_FOCUS_SEARCH_VALUE.key,
        SHORTCUT_FOCUS_SEARCH_VALUE.shortcut,
    );
    window.addEventListener('keydown', this.onWindowKeypress, true);
    await this.fetch();
  },
  destroyed() {
    window.removeEventListener('keydown', this.onWindowKeypress, true);
    removeShortcut(SHORTCUT_FOCUS_SEARCH_VALUE.key);
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return this.isEditMultipleNumberPopupOpen;
    },
    dayOfWeekCaption() {
      return getDayOfWeekCaption(this.searchConditions.visitDate);
    },
    visitDateValueModel: {
      get() {
        return getFormattedDate(this.searchConditions.visitDate);
      },
      set(visitDateValueModel) {
        this.searchConditions.visitDate = moment(visitDateValueModel).toDate();
      },
    },
    courseCodeDropdownListProps() {
      const dataSource = JSON.parse(JSON.stringify(commonCodesGetCommonCode('COURSE_CODE', true)));
      dataSource.unshift({
        comCode: '',
        comName: '전체',
      });
      return {
        allowFiltering: false,
        dataSource,
        fields: {text: 'comName', value: 'comCode'},
      };
    },
  },
  methods: {
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    commonCodesGetColorValue,
    numberWithCommas,
    async onViewVisitScoreRegistration() {
      await this.fetch();
    },
    async onSaveVisitScoreRegistration() {
      const gridInstance = this.$refs.visitScoreRegistrationGrid;
      gridInstance.endEdit();
      await this.$nextTick();
      const {changedRecords} = gridInstance.getBatchChanges();
      if (changedRecords.length > 0) {
        try {
          let request = [];
          changedRecords
              .forEach(item => {
                const ProcessedScoreInfo = {};
                if (item.holeDivLabel === 'SCORE') {
                  for (let i = 1; i <= 18; i++) {
                    const scoreId = `score${i}`;
                    const scoreItem = item[scoreId];
                    if (typeof scoreItem === 'number') {
                      const score = Number(scoreItem);
                      const parScore = item.parScoreInfo[scoreId];
                      ProcessedScoreInfo[scoreId] = this.isOver === 'OVER'
                          ? score + parScore
                          : score;
                    } else {
                      if (scoreItem === null) {
                        ProcessedScoreInfo[`${scoreId}DelFlag`] = true;
                      }
                    }
                  }
                }
                request.push({
                  visitId: item.visitId,
                  startCourse: item.startCourse,
                  endCourse: item.endCourse,
                  teeingground: item.teeingground,
                  ...ProcessedScoreInfo,
                });
              });
          await GolfErpAPI.putVisitScoreList(request);
          await this.fetch();
          this.infoToast(this.$t('main.popupMessage.saved'));
        } catch (err) {
          throw new Error(err);
        }
      } else {
        this.errorToast(this.$t('main.popupMessage.noDataToSave'));
      }
    },
    handleSearchConditionsBsnDateChange(args) {
      this.fetch();
      this.visitDateInfo = args;
    },
    findMemberNameAndCaddieName() {
      this.searchValue = this.searchValue.trim();
      if (!this.searchValue || this.searchValue === '') {
        this.errorToast(this.$t('main.popupMessage.noSearchValue'));
        return;
      }
      let allowedChkinIdList = [];
      this.pureVisitScoreRegistrationInfoList.forEach(item => {
        if (
            this.searchValue &&
            this.searchValue !== '' &&
            (
                item.reserveName?.includes(this.searchValue) ||
                item.visitName?.includes(this.searchValue)
            ) &&
            allowedChkinIdList.indexOf(item.id) < 0
        ) {
          allowedChkinIdList.push(item.id);
        }
      });
      const result = this.pureVisitScoreRegistrationInfoList
          .filter(item => allowedChkinIdList.includes(item.id));
      if (result.length < 1) {
        this.visitScoreRegistrationInfoList = this.pureVisitScoreRegistrationInfoList;
        this.errorToast(this.$t('main.popupMessage.noSearchResult'));
        return;
      }
      this.visitScoreRegistrationInfoList = result;
    },
    onVisitScoreRegistrationGridSectionBodyKeyDown(e) {
      const gridInstance = this.$refs.visitScoreRegistrationGrid;
      const editingCellField = gridInstance.editingCellField;
      const currentRowIndex = gridInstance.currentSelectedRowIndex;
      const getDataRowsCount = gridInstance.getDataRows().length;
      const dropdownListColumns = ['startCourse', 'endCourse'];
      const prevMove = () => {
        // eslint-disable-next-line no-case-declarations
        let fieldList = [
          'startCourse',
          'endCourse',
          'teeingground',
        ];
        for (let i = 1; i <= 18; i++) {
          fieldList.push(`score${i}`);
        }
        if (fieldList.includes(editingCellField)) {
          e.stopPropagation();
          e.preventDefault();
          if (editingCellField === 'startCourse') {
            if (currentRowIndex > 0) {
              gridInstance.editCell(currentRowIndex - 1, 'score18');
            }
          } else if (editingCellField === 'score1') {
            const holeDivLabel = gridInstance.getBatchCurrentViewRecords()
                .find((_, index) => index === currentRowIndex - 1)?.holeDivLabel;
            gridInstance.editCell(
                currentRowIndex - 1,
                holeDivLabel === 'PAR'
                    ? 'endCourse'
                    : 'score18',
            );
          } else {
            const prevIndex = fieldList.findIndex(item => item === editingCellField) - 1;
            gridInstance.editCell(
                currentRowIndex,
                fieldList[prevIndex],
            );
          }
        }
      };
      const nextMove = () => {
        // eslint-disable-next-line no-case-declarations
        let fieldList = [
          'startCourse',
          'endCourse',
          'teeingground',
        ];
        for (let i = 1; i < 18; i++) {
          fieldList.push(`score${i}`);
        }
        if (fieldList.includes(editingCellField)) {
          e.stopPropagation();
          e.preventDefault();
          if (editingCellField === 'endCourse') {
            const holeDivLabel = gridInstance.getBatchCurrentViewRecords()
                .find((_, index) => index === currentRowIndex)?.holeDivLabel;
            if (holeDivLabel === 'PAR') {
              if (currentRowIndex < getDataRowsCount - 1) {
                gridInstance.editCell(currentRowIndex + 1, 'teeingground');
              }
              return;
            }
          }
          gridInstance.editCell(
              currentRowIndex,
              editingCellField === 'score17'
                  ? 'score18'
                  : fieldList[fieldList.findIndex(item => item === editingCellField) + 1],
          );
        } else if (editingCellField === 'score18') {
          e.stopPropagation();
          e.preventDefault();
          if (currentRowIndex === getDataRowsCount - 1) {
            // gridInstance.saveCell();
            // gridInstance.editCell(0, 'startCourse');
          } else if (currentRowIndex < getDataRowsCount - 1) {
            const holeDivLabel = gridInstance.getBatchCurrentViewRecords()
                .find((_, index) => index === currentRowIndex + 1)?.holeDivLabel;
            gridInstance.editCell(currentRowIndex + 1, holeDivLabel === 'PAR' ? 'startCourse' : 'score1');
          }
        }
      };
      // TODO : 숫자 입력시 바로 다음 셀로 이동되는 기능 구현해야 함.
      // if (["0","1","2","3","4","5","6","7","8","9"].includes(e.key)) {
      //   const moveCursor = (rowIndex, field) => gridInstance.editCell(rowIndex, field);
      //   const currentViewRecords = gridInstance.getBatchCurrentViewRecords();
      //   const rowData = currentViewRecords
      //       .find((_, index) => index === currentRowIndex);
      //   const score = rowData[editingCellField];
      //   const par = rowData.parScoreInfo[editingCellField];
      //   if (par) {
      //     console.log(score);
      //   }
      //   let fieldList = [];
      //   for (let i = 1; i <= 18; i++) {
      //     fieldList.push(`score${i}`);
      //   }
      //   const nextIndex = fieldList.findIndex(item => item === editingCellField) + 1;
      //   moveCursor(currentRowIndex, fieldList[nextIndex]);
      // }
      switch (e.key) {
        case 'Tab':
          e.shiftKey
              ? prevMove()
              : nextMove();
          break;
        case 'ArrowLeft':
          prevMove();
          break;
        case 'Enter':
        case 'ArrowRight':
          nextMove();
          break;
        case 'ArrowUp':
          if (editingCellField) {
            if (
                !dropdownListColumns.includes(editingCellField) &&
                currentRowIndex > 0
            ) {
              e.stopPropagation();
              e.preventDefault();
              gridInstance.editCell(currentRowIndex - 1, editingCellField);
              const currentViewRecords = gridInstance.getBatchCurrentViewRecords()
                  .find((_, index) => index === currentRowIndex - 1);
              if (
                  editingCellField.includes('score') &&
                  currentViewRecords.holeDivLabel === 'PAR'
              ) {
                if (currentRowIndex === 1) {
                  gridInstance.editCell(1, editingCellField);
                } else {
                  this.onVisitScoreRegistrationGridSectionBodyKeyDown(e);
                }
              }
            }
          }
          break;
        case 'ArrowDown':
          if (editingCellField) {
            if (
                !dropdownListColumns.includes(editingCellField) &&
                currentRowIndex < getDataRowsCount - 1
            ) {
              e.stopPropagation();
              e.preventDefault();
              gridInstance.editCell(currentRowIndex + 1, editingCellField);
              const currentViewRecords = gridInstance.getBatchCurrentViewRecords()
                  .find((_, index) => index === currentRowIndex + 1);
              if (
                  editingCellField.includes('score') &&
                  currentViewRecords.holeDivLabel === 'PAR'
              ) {
                this.onVisitScoreRegistrationGridSectionBodyKeyDown(e);
              }
            }
          }
          break;
        case 'Escape':
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    onVisitEjsDropdownListEditTemplateChanged(args) {
      const {
        columnName,
        value,
        rowData: data,
      } = args;
      if (['startCourse', 'endCourse'].includes(columnName)) {
        const gridInstance = this.$refs.visitScoreRegistrationGrid;
        const parInfo = this.getParInfo({
          startCourse: columnName === 'startCourse'
              ? value
              : data.startCourse,
          endCourse: columnName === 'endCourse'
              ? value
              : data.endCourse,
        });
        const modifiedRowIndex = gridInstance.getRowIndexByPrimaryKey(data._rid);
        const updateScoreCell = (input, index = modifiedRowIndex) => {
          const tot = input.firstScore + input.lateScore;
          const outScore = input.firstScore - parInfo.outScore;
          const inScore = input.lateScore - parInfo.inScore;
          const totScore = tot - parInfo.totScore;
          const sumScore = {
            outScoreSum: input.firstScore,
            outScorePlus: outScore < 0
                ? outScore : outScore === 0
                    ? 'E' : '+' + outScore,
            inScoreSum: input.lateScore,
            inScorePlus: inScore < 0
                ? inScore : inScore === 0
                    ? 'E' : '+' + inScore,
            totScoreSum: tot,
            totScorePlus: totScore < 0
                ? totScore : totScore === 0
                    ? 'E' : '+' + totScore,
          };
          sumScore.outScore = input.firstScore > 0
              ? `${sumScore.outScoreSum} (${sumScore.outScorePlus})`
              : '-';
          sumScore.inScore = input.lateScore > 0
              ? `${sumScore.inScoreSum} (${sumScore.inScorePlus})`
              : '-';
          sumScore.totScore = tot > 0
              ? `${sumScore.totScoreSum} (${sumScore.totScorePlus})`
              : '-';
          gridInstance.updateCell(index, 'outScoreSum', sumScore.outScoreSum);
          gridInstance.updateCell(index, 'outScorePlus', sumScore.outScorePlus);
          gridInstance.updateCell(index, 'outScore', sumScore.outScore);
          gridInstance.updateCell(index, 'inScoreSum', sumScore.inScoreSum);
          gridInstance.updateCell(index, 'inScorePlus', sumScore.inScorePlus);
          gridInstance.updateCell(index, 'inScore', sumScore.inScore);
          gridInstance.updateCell(index, 'totScoreSum', sumScore.totScoreSum);
          gridInstance.updateCell(index, 'totScorePlus', sumScore.totScorePlus);
          gridInstance.updateCell(index, 'totScore', sumScore.totScore);
        };
        if (data.holeDivLabel === 'PAR') {
          for (let i = 1; i <= 18; i++) {
            const scoreId = `score${i}`;
            gridInstance.updateCell(modifiedRowIndex, scoreId, parInfo[scoreId]);
          }
          ['outScore', 'inScore', 'totScore'].forEach(item => {
            gridInstance.updateCell(modifiedRowIndex, item, parInfo[item]);
          });
          const currentViewRecords = gridInstance.getBatchCurrentViewRecords()
              .filter(c => c.id === data.id && c.holeDivLabel === 'SCORE');
          currentViewRecords.forEach(c => {
            const cIdx = gridInstance.getRowIndexByPrimaryKey(c._rid);
            gridInstance.updateCell(cIdx, columnName, value);
            updateScoreCell(c, cIdx);
          });
        }
      }
    },
    async visitScoreRegistrationGridCellEdit(args) {
      const {
        cell,
        columnName: field,
        value,
      } = args;
      if (field.startsWith('score') && value !== null && value !== undefined) {
        await this.$nextTick();
        const $el = cell?.firstElementChild?.firstElementChild?.firstElementChild;
        if ($el) {
          const len = String(value).length;
          if (len > 0) {
            $el.setSelectionRange(0, String(value).length);
          }
        }
      }
    },
    visitScoreRegistrationGridCellSaved(args) {
      const {
        cell,
        columnName: field,
        value,
        rowData: data,
      } = args;
      if (field.startsWith('score') && data.holeDivLabel === 'SCORE') {
        if (typeof value === 'number') {
          if (!this.isModifyMode) {
            const {
              fontColor,
              backgroundColor,
            } = this.getScoreColorValue(data.parScoreInfo[field], value);
            cell.style.color = fontColor;
            cell.style.backgroundColor = backgroundColor;
          }
        } else {
          cell.style.backgroundColor = null;
          cell.innerText = '-';
        }
      }
    },
    visitScoreRegistrationGridRecordClick(args) {
      this.selectedVisitScoreRegistrationInfo = args.data;
    },
    visitScoreRegistrationGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if ([
        'startCourse',
        'holeDivLabel',
        'score1',
        'score10',
        'outScore',
      ].includes(field)) {
        node.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (field === 'holeDivLabel') {
        node.style.color = '#FFF';
        node.style.backgroundColor = '#2e7458';
      }
      if (this.isModifyMode && headerText !== 'NO') {
        let columns = [];
        this.visitScoreRegistrationGridColumn
            ?.forEach(column => {
              if (column?.columns) {
                column.columns
                    ?.forEach(c => columns.push(c));
              } else {
                columns.push(column);
              }
            });
        const allowedEditColumns = columns
            ?.filter(item => item.allowEditing && item.isHorizontalEdit)
            ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          // node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    visitScoreRegistrationGridQueryCellInfo(args) {
      const {
        cell,
        column: {field, allowEditing},
        data,
      } = args;
      // TODO : 셀 병합 버그 있음.
      /*if (data.holeDivLabel === 'PAR') {
        if ([
          'no',
          'time',
          'course',
          'reserveName',
          'groupName',
          'startCourse',
          'endCourse',
        ].includes(field)) {
          args.rowSpan = data.visitorsCount + 1;
          cell.style.borderBottom = '2px solid #999';
        }
        if (field === 'visitName') {
          args.colSpan = 2;
        }
      }*/
      if ([
        'startCourse',
        'endCourse',
      ].includes(field)) {
        if (data.holeDivLabel === 'PAR') {
          cell.classList.add(this.$t('className.grid.modifyArea'));
        } else {
          cell.style.backgroundColor = '#fefefe';
          cell.style.pointerEvents = 'none';
          cell.innerText = '';
        }
      }
      if (field === 'teeingground') {
        if (data.holeDivLabel === 'PAR') {
          cell.style.backgroundColor = '#fefefe';
          cell.style.pointerEvents = 'none';
          cell.innerText = '';
        } else {
          cell.classList.add(this.$t('className.grid.modifyArea'));
        }
      }
      if (field.startsWith('score')) {
        if (data.holeDivLabel === 'PAR') {
          cell.style.backgroundColor = '#8ED2B7';
          cell.style.pointerEvents = 'none';
        } else if (data.holeDivLabel === 'SCORE') {
          const parScore = data.parScoreInfo[field];
          const score = data[field];
          if (typeof score === 'number') {
            if (!this.isModifyMode) {
              const {
                fontColor,
                backgroundColor,
              } = this.getScoreColorValue(parScore, Number(score));
              cell.style.color = fontColor;
              cell.style.backgroundColor = backgroundColor;
            }
          } else {
            cell.style.backgroundColor = null;
            cell.innerText = '-';
          }
          cell.classList.add(this.$t('className.grid.modifyArea'));
        }
      }
      if ([
        'startCourse',
        'holeDivLabel',
        'score1',
        'score10',
        'outScore',
      ].includes(field)) {
        cell.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (data?.teamLine) {
        cell.style.borderBottom = '2px solid #999';
      }
      if (!allowEditing && field !== 'no') {
        cell.style.backgroundColor = '#fefefe';
      }
      [
        'outScore',
        'inScore',
        'totScore',
      ].forEach(item => {
        if (item.includes(field)) {
          if (data.holeDivLabel === 'PAR') {
            cell.style.backgroundColor = '#8ED2B7';
          } else {
            if (data[field] !== '-') {
              cell.innerHTML = `<b style="font-size: 14px">${data[field + 'Sum']}</b> (${data[field + 'Plus']})`;
            }
          }
        }
      });
      if (field === 'holeDivLabel') {
        cell.style.color = '#FFF';
        cell.style.backgroundColor = '#2e7458';
      }
      if (field === 'visitName') {
        const visitName = `<span>${data[field].replace(/ⓒ/gim, '')}</span>`;
        if (data[field]?.includes('ⓒ')) {
          cell.classList.add(this.$t('className.grid.caddieArea'));
        }
        cell.classList.add(this.$t('className.grid.selectedCellBold'));
        cell.innerHTML = visitName;
      }
      if ([
        'reserveName',
        'visitName',
      ].includes(field) && this.searchValue && this.searchValue !== '' && data[field]?.includes(this.searchValue)) {
        cell.innerHTML = `<span style='font-weight: bold; background: yellow'>${data[field].replace(/ⓒ/gim, '')}</span>`;
      }
      if (field === "holeDivision") {
        cell.style.backgroundColor = commonCodesGetColorValue(
          "HOLE_DIV",
          data.holeDivision
        );
      }
    },
    excel() {
      this.$refs.visitScoreRegistrationGrid.excelExport();
    },

    // API
    async fetch() {
      const {teams, courseHoleInfos} = await GolfErpAPI.fetchVisitScoreList({
        visitDate: moment(this.searchConditions.visitDate).format(DATE_FORMAT_YYYY_MM_DD),
        time: this.time !== '00:00'
            ? this.time
            : null,
        courseCode: this.courseCode,
      });
      const sortedTeams = _sortBy(teams.map(team => ({
        ...team,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", team.course)})
      ), ['courseCodeSortNo', 'time']);
      this.courseHoleInfos = courseHoleInfos;
      let result = [];
      let _rid, no = 0;
      sortedTeams.forEach(item => {
        let parInfo = {};
        item.visitors?.forEach((visitor, index) => {
          const hasProp = (arr, key) => Object.prototype.hasOwnProperty.call(arr, key);
          const visitInfo = {
            id: item.id,
            visitId: visitor.id,
            visitName: visitor.name,
            webId: (
                hasProp(visitor, 'membership') &&
                hasProp(visitor.membership, 'member') &&
                hasProp(visitor.membership.member, 'webIdInformation')
            )
                ? visitor.membership.member.webIdInformation.id
                : null,
          };
          let endCourse;
          const jsonData = commonCodesGetCommonCodeByIdx('GAME_COURSE', 1)
              .find(i => i.comCode === item.course)?.jsonData;
          if (jsonData && jsonData.length > 0) {
            const data = JSON.parse(jsonData);
            if (data && data.length > 0) {
              endCourse = data[0].ATTRB;
            }
          }
          const defaultCourseInfo = {
            startCourse: item.course,
            endCourse,
            teeingground: commonCodeGetComCodeDefaultValue('TEEINGGROUND'),
          };
          const courseInfo = {};
          [
            'startCourse',
            'endCourse',
            'teeingground',
          ].forEach(i => {
            courseInfo[i] = hasProp(visitor, 'visitScore') && hasProp(visitor.visitScore, i)
                ? visitor.visitScore[i]
                : defaultCourseInfo[i];
          });
          if (index === 0) {
            item.startCourse = courseInfo.startCourse;
            item.endCourse = courseInfo.endCourse;
            item.teeingground = courseInfo.teeingground;
            item.holeDivLabel = 'PAR';
            item.visitorsCount = item.visitors.length;
            parInfo = this.getParInfo(item);
            result.push({
              _rid: _rid++,
              no: ++no,
              id: item.id,
              visitId: visitor.id,
              time: item.time,
              course: item.course,
              reserveName: item.reserveName,
              groupName: item.groupName,
              visitName: item.teamCaddies?.length > 0
                  ? 'ⓒ ' + item.teamCaddies.map(caddie => `${caddie.caddie.name} (${caddie.cartNumber})`)?.join(', ')
                  : '',
              ...courseInfo,
              ...parInfo,
              holeDivLabel: item.holeDivLabel,
            });
          }
          let visitScore = {};
          let getVisitScoreList = ['firstScore', 'lateScore'];
          for (let i = 1; i <= 18; i++) {
            getVisitScoreList.push(`score${i}`);
          }
          getVisitScoreList.forEach(item => {
            if (hasProp(visitor, 'visitScore') && hasProp(visitor.visitScore, item)) {
              if (item === 'firstScore' || item === 'lateScore') {
                visitScore[item] = visitor.visitScore[item];
              } else {
                visitScore[item] = this.isOver === 'OVER'
                    ? visitor.visitScore[item] - parInfo[item]
                    : visitor.visitScore[item];
              }
            }
          });
          const tot = visitScore.firstScore + visitScore.lateScore;
          const outScore = visitScore.firstScore - parInfo.outScore;
          const inScore = visitScore.lateScore - parInfo.inScore;
          const totScore = tot - parInfo.totScore;
          const sumScore = {
            outScoreSum: visitScore.firstScore,
            outScorePlus: outScore < 0
                ? outScore : outScore === 0
                    ? 'E' : '+' + outScore,
            inScoreSum: visitScore.lateScore,
            inScorePlus: inScore < 0
                ? inScore : inScore === 0
                    ? 'E' : '+' + inScore,
            totScoreSum: tot,
            totScorePlus: totScore < 0
                ? totScore : totScore === 0
                    ? 'E' : '+' + totScore,
          };
          sumScore.outScore = visitScore.firstScore > 0
              ? `${sumScore.outScoreSum} (${sumScore.outScorePlus})`
              : '-';
          sumScore.inScore = visitScore.lateScore > 0
              ? `${sumScore.inScoreSum} (${sumScore.inScorePlus})`
              : '-';
          sumScore.totScore = tot > 0
              ? `${sumScore.totScoreSum} (${sumScore.totScorePlus})`
              : '-';
          const parScoreInfo = {};
          for (let i = 1; i <= 18; i++) {
            const scoreId = `score${i}`;
            parScoreInfo[scoreId] = parInfo[scoreId];
          }
          result.push({
            _rid: _rid++,
            ...visitInfo,
            ...courseInfo,
            parScoreInfo,
            holeDivLabel: 'SCORE',
            ...visitScore,
            ...sumScore,
            teamLine: item.visitors.length === index + 1,
          });
        });
      });
      this.pureVisitScoreRegistrationInfoList = this.visitScoreRegistrationInfoList = result
        ?.map(item => {
          if (item?.webId) {
            if (["KAKAO", "NAVER"].includes(item.webId?.split(":")?.[0])) {
              item.webId = item.webId?.split(":")?.[0];
            }
          }
          return item;
        });
      this.count = teams.length;
      setTimeout(() => {
        this.$refs.searchValue.focus();
      }, 500);
    },
    getParInfo(item) {
      item.outScore = item.inScore = item.totScore = 0;
      for (let i = 1; i <= 18; i++) {
        const scoreId = `score${i}`;
        item[scoreId] = this.courseHoleInfos.find(c =>
            c.courseCode === (i < 10 ? item.startCourse : item.endCourse) &&
            c.holeDiv === String(i < 10 ? i : (i % 10) + 1))?.par || 0;
        i < 10
            ? item.outScore += item[scoreId]
            : item.inScore += item[scoreId];
      }
      item.totScore = item.outScore + item.inScore;
      return item;
    },
    getScoreColorValue(parScore, score) {
      let processedScore = this.isOver === 'OVER'
          ? score
          : score - parScore;
      if (
          (parScore === 3 && processedScore === -2) ||
          (parScore === 4 && processedScore === -3) ||
          (parScore === 5 && (processedScore === -3 || processedScore === -4))
      ) {
        processedScore = 'ACE';
      }
      if (processedScore < -3) {
        processedScore = -3;
      } else if (processedScore > 2) {
        processedScore = 2;
      }
      const scoreLabel = SCORE_LABEL[String(processedScore)];
      const backgroundColor = commonCodesGetColorValue('SCORE_DIV', scoreLabel);
      let colorCount = 0;
      const addColorCount = v => colorCount += v < 128 ? 1 : 0;
      const {r, g, b} = this.hexToRgb(backgroundColor);
      [r, g, b].forEach(i => addColorCount(i));
      return {
        fontColor: backgroundColor && colorCount >= 2
            ? '#FFF'
            : '#000',
        backgroundColor: scoreLabel
            ? backgroundColor
            : '#FFF',
      };
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
          ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
          : null;
    },
    onWindowKeypress(event) {
      if (
          keypressEventEqualsShortcut(event, SHORTCUT_FOCUS_SEARCH_VALUE.shortcut)
      ) {
        this.$refs.searchValue.focus();
      }
    },
    onEditMultipleNumberPopupConfirm(args) {
      const {
        fields,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleNumberPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      for (const index of rowIndexes) {
        for (const field of fields) {
          this.$refs.visitScoreRegistrationGrid.updateCell(
              index,
              field,
              value,
          );
        }
      }
    },
    onEditMultipleNumberPopupClose() {
      this.isEditMultipleNumberPopupOpen = false;
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
          ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
          : {enableToggle: false, mode: 'Both', type: 'Multiple'};
      this.$refs.visitScoreRegistrationGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 여러 셀과 로우를 드래그하세요.');
      }
    },
    async cellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {selectedRowCellIndex} = args;
      const cellIndexes = selectedRowCellIndex[0]?.cellIndexes;
      let columns = [];
      this.visitScoreRegistrationGridColumn
          ?.forEach(column => {
            if (column?.columns) {
              column.columns
                  ?.forEach(c => columns.push(c));
            } else {
              columns.push(column);
            }
          });
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      const allowedEditColumns = columns
          ?.filter(item => item.allowEditing && item.isHorizontalEdit)
          ?.map(item => item.field);
      let editColumns = [];
      cellIndexes?.forEach(c => editColumns.push(columns[c]?.field));
      const hasNotHorizontalEdit = editColumns
          ?.filter(field => !allowedEditColumns?.includes(field));
      if (hasNotHorizontalEdit.length > 0) {
        this.errorToast('멀티 편집할 수 없는 컬럼이 포함되어 있습니다.');
        return;
      }
      this.isEditMultipleNumberPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleNumberPopup.showPopup(
          editColumns,
          selectedRowIndexes,
          {
            min: -9,
            max: 9,
            propMaxLength: 1,
            allowDot: false,
            allowMinus: true,
            displayComma: false,
          },
          true,
      );
    },
  },
};
</script>
