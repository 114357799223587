<template>
  <div>
    <ejs-dialog
      ref="editMultipleNumberPopup"
      header="다중 숫자 편집"
      :width="280"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      :close="onPopupClosed"
    >
      <div class="window reservationTimeGeneration">
        <div
          class="windowContent"
          @keydown.capture="onKeyDownInputNumber"
        >
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    숫자
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-number
                        ref="focusArea"
                        v-model="value"
                        :allowDot="multiEdit.allowDot"
                        :allowMinus="multiEdit.allowMinus"
                        :displayComma="multiEdit.displayComma"
                        :min="multiEdit.min"
                        :max="multiEdit.max"
                        :propMaxLength="multiEdit.propMaxLength"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="submit()"
              >
                확인
              </erp-button>
            </li>
            <li v-if="isClearVisible">
              <erp-button button-div="SAVE" @click.native="submit(true)">
                NULL
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closePopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import InputNumber from "@/components/common/InputNumber";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'EditMultipleNumberPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputNumber,
    ErpButton,
  },
  data() {
    return {
      fields: [],
      value: null,
      selectedRowIndexes: [],
      multiEdit: {
        min: null,
        max: null,
        propMaxLength: null,
        allowDot: false,
        allowMinus: false,
        displayComma: false,
      },
      isClearVisible: false,
    };
  },
  methods: {
    onPopupClosed() {
      this.$emit('popupClosed');
    },
    async showPopup(fields, selectedRowIndexes, multiEdit = null, isClearVisible = false) {
      this.fields = fields;
      this.selectedRowIndexes = selectedRowIndexes;
      if (multiEdit) {
        this.multiEdit = multiEdit;
      }
      this.isClearVisible = isClearVisible;
      await this.$nextTick();
      setTimeout(() => this.$refs.focusArea?.focus(), 100);
    },
    closePopup() {
      this.$refs.editMultipleNumberPopup?.hide();
    },
    async onKeyDownInputNumber({key}) {
      if (key === 'Enter') {
        await this.submit();
      }
    },
    async submit(clear = false) {
      this.$emit('popupConfirm', {
        fields: this.fields,
        value: clear ? null : this.value,
        rowIndexes: this.selectedRowIndexes,
      });
    },
  },
};
</script>
